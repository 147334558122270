import { default as $t } from 'o365.modules.translate.ts';
const typedOperators = {
    string: ["contains", "notcontains", "equals", "notequals", "beginswith", "endswith", "notendswith", "isnotblank", "isblank", "notinlist", "inlist"],
    boolean: ["istrue", "isfalse"],
    bit: ["istrue", "isfalse"],
    number: ["equals", "notequals", "greaterthanorequal", "greaterthan", "lessthanorequal", "lessthan", "isnull", "isnotnull", "between",'notbetween'],
    date: ["dateequals", "dategreaterthan", "dategreaterthanorequal", "datelessthan", "datelessthanorequal", "datebetween", "datenotbetween", "isnull", "isnotnull"],
    datetime: ["dateequals", "dategreaterthan", "dategreaterthanorequal", "datelessthan", "datelessthanorequal", "datebetween", "datenotbetween", "isnull", "isnotnull"],
    time: ["timeequals", "timebefore", "timeafter", "timebetween", "timenotbetween", "isnull", "isnotnull"],
    uniqueidentifier: ["equals", "notequals", "isnotnull", "isnull", "notinlist", "inlist"]
}
const operatorTitles = {
    equals: $t('Equals'),
    greaterthan: $t('Greater than'),
    lessthan: $t('Less than'),
    beginswith: $t('Starts with'),
    endswith: $t('Ends with'),
    contains: $t('Contains'),
    isnull: $t('Is Null'),
    istrue: $t('Is true'),
    inlist: $t('In list'),
    between: $t('Between'),
    like: $t('Contains'),
    isblank: $t('Is empty'),
    notequals: $t('Not equals'),
    greaterthanorequal: $t('Greater than or equal'),
    lessthanorequal: $t('Less than or equal'),
    notbeginswith: $t('Not starts with'),
    notendswith: $t('Not ends with'),
    notcontains: $t('Not contains'),
    isnotnull: $t('Is not null'),
    isfalse: $t('Is false'),
    notinlist: $t('Not in list'),
    notbetween: $t('Not between'),
    dateequals: $t('Equals'),
    //  datenotequals: $t('Not equals'), 
    datebetween: $t('Between'),
    datenotbetween: $t('Not between'),
    timebetween: $t('Time between'),
    timeequals: $t('Time equals'),
    timebefore: $t('Time before'),
    timeafter: $t('Time after'),
    notlike: $t('Not contains'),
    isnotblank: $t('Is not empty'),
    dategreaterthan: $t('Greater than'),
    datelessthan: $t('Less than '),
    dategreaterthanorequal: $t('Greater than or equal'),
    datelessthanorequal: $t('Less than or equal'),
    exists_clause: $t('Exists'),
    timenotbetween: $t('Not between'),
};

const operators = {
    EQUALS: "equals",
    GREATERTHAN: "greaterthan",
    LESSTHAN: "lessthan",
    BEGINSWITH: "beginswith",
    ENDSWITH: "endswith",
    CONTAINS: "contains",
    ISNULL: "isnull",
    ISTRUE: "istrue",
    INLIST: "inlist",
    BETWEEN: "between",
    LIKE: "like",
    ISBLANK: "isblank",
    NOTEQUALS: "notequals",
    GREATERTHANOREQUAL: "greaterthanorequal",
    LESSTHANOREQUAL: "lessthanorequal",
    NOTBEGINSWITH: "notbeginswith",
    NOTENDSWITH: "notendswith",
    NOTCONTAINS: "notcontains",
    ISNOTNULL: "isnotnull",
    ISFALSE: "isfalse",
    NOTINLIST: "notinlist",
    NOTBETWEEN: "notbetween",
    DATEEQUALS: "dateequals",
    TIMEBETWEEN: "timebetween",
    TIMEEQUALS: "timeequals",
    TIMEBEFORE: "timebefore",
    TIMEAFTER: "timeafter",
    NOTLIKE: "notlike",
    NOTDATEQUALS: "notdatequals",
    DATEGREATERTHAN: "dategreaterthan",
    DATEGREATERTHANOREQUAL: "dategreaterthanorequal",
    DATELESSTHAN: "datelessthan",
    DATELESSTHANOREQUAL: "datelessthanorequal",
    DATEBETWEEN: "datebetween",
    DATENOTBETWEEN: "datenotbetween",
    ISNOTBLANK: "isnotblank",
    EXISTS_CLAUSE: "exists_clause",
    TIMENOTBETWEEN: "timenotbetween",
};

const valueTypes = {
    NULL: "null",
    STRING: "string",
    NUMBER: "number",
    BOOLEAN: "boolean",
    UNIQUEIDENTIFIER: "uniqueidentifier",
    ARRAY: "array",
    DATETIME: "datetime",
    DATE: "date",
    TIME: "time",
    SPECIAL: "special"
};

const operatorDefaults = {
    string: "contains",
    boolean: "istrue",
    bit: "istrue",
    number: "equals",
    date: "datebetween",//"dateequals",
    time: "timeequals",
    datetime: "datebetween",//"dateequals",
    uniqueidentifier: "equals"
}


export {typedOperators,operatorTitles,operators,valueTypes,operatorDefaults}